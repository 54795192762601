<template>
  <div>
    <pl-table
      use-virtual
      class="tb-edit"
      :data="tableData"
      border
      stripe
      width="3340"
      height="430"
      :cell-style="{padding:0,height:'28px'}"
      :header-cell-style="{padding:0,height:'28px'}"
      :row-height="28"
    >
      <pl-table-column prop="状态信息" label="状态信息" width="120" :fixed="fixedCol==='状态信息'">
        <template slot="header">
          状态信息 <span @click="fixedCol='状态信息'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="来源" label="来源" width="120" :fixed="fixedCol==='来源'">
        <template slot="header">
          来源 <span @click="fixedCol='来源'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="来源厂家" label="来源厂家" width="120" :fixed="fixedCol==='来源厂家'">
        <template slot="header">
          来源厂家 <span @click="fixedCol='来源厂家'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="最新动作" label="最新动作" width="100" :fixed="fixedCol==='最新动作'">
        <template slot="header">
          最新动作 <span @click="fixedCol='最新动作'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="出库动作" label="出库动作" width="100" :fixed="fixedCol==='出库动作'">
        <template slot="header">
          出库动作 <span @click="fixedCol='出库动作'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="备注" label="备注" width="200"></pl-table-column>
      <pl-table-column prop="发货时间" label="发货时间" width="110" :fixed="fixedCol==='发货时间'">
        <template slot="header">
          发货时间 <span @click="fixedCol='发货时间'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="客户" label="客户" width="120" :fixed="fixedCol==='客户'">
        <template slot="header">
          客户 <span @click="fixedCol='客户'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="业务员" label="业务员" width="100" :fixed="fixedCol==='业务员'">
        <template slot="header">
          业务员 <span @click="fixedCol='业务员'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="波段" label="波段" width="120" :fixed="fixedCol==='波段'">
        <template slot="header">
          波段 <span @click="fixedCol='波段'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="型号" label="型号" width="120" :fixed="fixedCol==='型号'">
        <template slot="header">
          型号 <span @click="fixedCol='型号'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="入库时间" label="入库时间" width="110" :fixed="fixedCol==='入库时间'">
        <template slot="header">
          入库时间 <span @click="fixedCol='入库时间'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="送货单日期" label="送货单日期" width="110" :fixed="fixedCol==='送货单日期'">
        <template slot="header">
          送货单日期 <span @click="fixedCol='送货单日期'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="Wafer_ID" label="Wafer ID" width="200" :fixed="fixedCol==='Wafer_ID'">
        <template slot="header">
          Wafer ID <span @click="fixedCol='Wafer_ID'"><i class="el-icon-s-flag"></i></span>
        </template>
      </pl-table-column>
      <pl-table-column prop="Bin_Value" label="Bin Value" width="135">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="Bin Value" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.Bin_Value"
              placeholder="Bin Value"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('Bin_Value')"
              size="mini"
            >
              <!--<el-option v-for="item in VF1" :value="item" :label="item"></el-option>-->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="VF1_MIN" label="VF1 MIN" width="135">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="VF1 MIN" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.VF1_MIN"
              placeholder="VF1 MIN"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('VF1_MIN')"
              size="mini"
            >
              <el-option v-for="item in defaultOptions.VF1" :value="item" :label="item"></el-option>
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="VF1_AVG" label="VF1 AVG" width="135">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="VF1 AVG" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.VF1_AVG"
              placeholder="VF1 AVG"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('VF1_AVG')"
              size="mini"
            >
              <el-option v-for="item in defaultOptions.VF1" :value="item" :label="item"></el-option>
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="VF1_MAX" label="VF1 MAX" width="135">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="VF1 MAX" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.VF1_MAX"
              placeholder="VF1 MAX"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('VF1_MAX')"
              size="mini"
            >
              <el-option v-for="item in defaultOptions.VF1" :value="item" :label="item"></el-option>
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="VF1_STD" label="VF1 STD" width="135">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="VF1 STD" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.VF1_STD"
              placeholder="VF1 STD"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('VF1_STD')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="LOP1_MIN" label="LOP1 MIN" width="140">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="LOP1 MIN" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.LOP1_MIN"
              placeholder="LOP1 MIN"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('LOP1_MIN')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="LOP1_AVG" label="LOP1 AVG" width="140">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="LOP1 AVG" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.LOP1_AVG"
              placeholder="LOP1 AVG"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('LOP1_AVG')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="LOP1_MAX" label="LOP1 MAX" width="140">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="LOP1 MAX" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.LOP1_MAX"
              placeholder="LOP1 MAX"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('LOP1_MAX')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="LOP1_STD" label="LOP1 STD" width="140">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="LOP1 STD" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.LOP1_STD"
              placeholder="LOP1 STD"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('LOP1_STD')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="WLP1_MIN" label="WLP1 MIN" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="WLP1 MIN" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.WLP1_MIN"
              placeholder="WLP1 MIN"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('WLP1_MIN')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="WLP1_AVG" label="WLP1 AVG" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="WLP1 AVG" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.WLP1_AVG"
              placeholder="WLP1 AVG"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('WLP1_AVG')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="WLP1_MAX" label="WLP1 MAX" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="WLP1 MAX" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.WLP1_MAX"
              placeholder="WLP1 MAX"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('WLP1_MAX')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="WLP1_STD" label="WLP1 STD" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="WLP1 STD" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.WLP1_STD"
              placeholder="WLP1 STD"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('WLP1_STD')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <!-- ################ -->
      <pl-table-column prop="WLD1_MIN" label="WLD1 MIN" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="WLD1 MIN" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.WLD1_MIN"
              placeholder="WLD1 MIN"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('WLD1_MIN')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="WLD1_AVG" label="WLD1 AVG" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="WLD1 AVG" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.WLD1_AVG"
              placeholder="WLD1 AVG"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('WLD1_AVG')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="WLD1_MAX" label="WLD1 MAX" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="WLD1 MAX" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.WLD1_MAX"
              placeholder="WLD1 MAX"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('WLD1_MAX')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="WLD1_STD" label="WLD1 STD" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="WLD1 STD" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.WLD1_STD"
              placeholder="WLD1 STD"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('WLD1_STD')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="IR_MIN" label="IR MIN" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="IR MIN" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.IR_MIN"
              placeholder="IR MIN"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('IR_MIN')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="IR_AVG" label="IR AVG" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="IR AVG" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.IR_AVG"
              placeholder="IR AVG"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('IR_AVG')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="IR_MAX" label="IR MAX" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="IR MAX" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.IR_MAX"
              placeholder="IR MAX"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('IR_MAX')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="IR_STD" label="IR STD" width="145">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="IR STD" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.IR_STD"
              placeholder="IR STD"
              filterable
              allow-create
              clearable
              default-first-option
              @change="selectColChange('IR_STD')"
              size="mini"
            >
              <!-- <el-option v-for="item in VF1" :value="item" :label="item"></el-option> -->
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <!-- ################ -->
      <pl-table-column prop="Qty" label="Qty" width="105">
        <template slot="header" slot-scope="scope">
          <el-tooltip content="Qty" placement="top" effect="light" transition="none">
            <el-select
              v-model="colScreen.Qty"
              placeholder="Qty"
              clearable
              filterable
              allow-create
              default-first-option
              @change="selectColChange('Qty')"
              size="mini"
            >
              <el-option value="asc" label="Qty ↑"></el-option>
              <el-option value="desc" label="Qty ↓"></el-option>
            </el-select>
          </el-tooltip>
        </template>
      </pl-table-column>
      <pl-table-column prop="SERIAL" label="SERIAL" width="150"></pl-table-column>
    </pl-table>
  </div>
</template>

<script>
import {defaultOptions} from '../configs/squareConfig'

export default {
  name: "SquareTable",
  props: ['tableData', 'myName'],
  data() {
    return {
      fixedCol: 'Wafer_ID',
      colScreen: {},
      defaultOptions: defaultOptions,
    }
  },
  methods: {
    selectColChange(col) {
      if (this.myName === 'rwTable') {
        this.$message({
          type: "error",
          message: '对不起，覆写功能需要同时处理新、旧两个数据，暂不支持筛选、排序'
        })
        this.colScreen = {}
        return
      }
      this.colScreen.myName = this.myName
      this.$emit('change-screen', this.colScreen)
    },
    dataChanged() {
      let 波段列表 = []
      this.tableData.forEach(item => {
        const chk = 波段列表.find(波段 => 波段 === item.波段)
        if (!chk) 波段列表.push(item.波段)
      })
    },
  },
  mounted() {
    this.dataChanged()
  },
  watch: {}
}
</script>

<style scoped>

</style>
